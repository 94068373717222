import React from 'react';

import {
    Container,
    Button
} from './DashElements';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        // Check if the user is logged in
        if (localStorage.getItem('token') === null) {
            window.location.href = '/auth/sign-in';
        }
    }

    render() {
        return (
            <Container>
                <h1>You don't have access to the Alpha!</h1>
                <Button href="/auth/sign-out">Sign out</Button>
            </Container>
        );
    }
}

export default Dashboard;