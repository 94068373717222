import React from 'react';
import logo from "images/annal-logo.svg";
import {
    Container,
    FormWrap,
    Icon,
    IconWrapper,
    FormContent,
    Form,
    FormH1,
    FormLabel,
    FormInput,
    FormButton,
    FormSmallLink,
    FormText,
    FormLink,
    FormLinksWrapper,
    FormAlert
} from 'components/FormElements';

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codeNeeded: false
        }
    }

    setAlert = (message, error = true, buttonText = "Continue") => {
        if (error) {
            document.getElementById('alert').style.color = "red";
        } else {
            document.getElementById('alert').style.color = "green";
        }

        document.getElementById('alert').innerHTML = message;

        // Reverse the form changes
        document.getElementById('submit').disabled = false;
        document.getElementById('submit').innerHTML = buttonText;
        try {
            document.getElementById('email').disabled = false;
            document.getElementById('password').disabled = false;
            document.getElementById('password-confirm').disabled = false;
        } catch (TypeError) {
            // Do nothing
        }
        try {
            document.getElementById('code').disabled = false;
        } catch (TypeError) {
            // Do nothing
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setAlert("");
        // Check to see if the passwords match
        if (document.getElementById('password').value !== document.getElementById('password-confirm').value) {
            this.setAlert("Passwords do not match");
            return;
        }
        
        // Send a PUT request to https://' + process.env.ANNAL_API +'/user/create
        // If the request fails, display an error message
        // If the request succeeds, save the token to localStorage and redirect to the dashboard
        const HTTP = new XMLHttpRequest();
        const url = process.env.REACT_APP_ANNAL_API +'/user';
        const data = new URLSearchParams(Object.entries({
            email: document.getElementById('email').value,
            password: document.getElementById('password').value,
        })).toString();
        HTTP.open('PUT', url);
        HTTP.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        HTTP.onload = () => {
            if (HTTP.status === 201) {
                this.setState({
                    codeNeeded: true
                })
                this.setAlert("A code was sent to your email.", false, "Sign Up")
                document.getElementById("code").value = "";
            } else if (HTTP.status === 429) {
                this.setAlert("Too many requests. Please try again later.");
            } else if (HTTP.status === 400) {
                const response = JSON.parse(HTTP.responseText);
                this.setAlert(response.detail);
            } else if (HTTP.status === 521) {
                this.setAlert("The API server is down. Please try again later.", true, "Continue");
            } else if (HTTP.status.toString().startsWith("5")) {
                this.setAlert("An error occurred. Please try again later.");
            }
        }
        
        // Set button to loading and disable it and the form
        document.getElementById('submit').disabled = true;
        document.getElementById('submit').innerHTML = "Loading...";
        document.getElementById('email').disabled = true;
        document.getElementById('password').disabled = true;
        document.getElementById('password-confirm').disabled = true;

        HTTP.send(data);
    }

    handleSubmitCode = (e) => {
        e.preventDefault();
        // Send a PUT request to https://' + process.env.ANNAL_API +'/user/{code}
        // If the request fails, display an error message
        // If the request succeeds, save the token to localStorage and redirect to the dashboard
        const HTTP = new XMLHttpRequest();
        const url = process.env.REACT_APP_ANNAL_API +'/user/' + document.getElementById('code').value;
        HTTP.open('PUT', url);
        HTTP.onload = () => {
            if (HTTP.status === 201) {
                window.location.href = "/auth/sign-in";
                this.setAlert("Account created successfully.", false, "Sign Up");
            } else if (HTTP.status === 429) {
                this.setAlert("Too many requests. Please try again later.", true, "Sign Up");
            } else if (HTTP.status === 400) {
                this.setAlert("Invalid code.", true, "Sign Up");
            }
        }
        HTTP.send();
    }

    render() {
        if (!this.state.codeNeeded) {
            return (
                <Container>
                    <FormWrap>
                        <FormContent>
                            <Form onSubmit={this.handleSubmit}>
                                <IconWrapper href="https://annal.app">
                                    <Icon src={logo}/>
                                </IconWrapper>
                                <FormH1>Create an account</FormH1>
                                <FormAlert id="alert"></FormAlert>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <FormInput id="email" type='email' required />
                                <FormLabel htmlFor='password'>Password</FormLabel>
                                <FormInput id="password" type='password' required />
                                <FormLabel htmlFor='password-confirm'>Confirm Password</FormLabel>
                                <FormInput id="password-confirm" type='password' required />
                                <FormText>By signing up you confirm you agree to our <FormSmallLink href="https://annal.app/privacy-policy">privacy policy</FormSmallLink> and <FormSmallLink href="https://annal.app/terms-of-use">terms of use</FormSmallLink>.</FormText>
                                <FormButton id="submit" type='submit' >Continue</FormButton>
                                <FormLinksWrapper>
                                    <FormLink to="/auth/sign-in">Have an account?</FormLink>
                                </FormLinksWrapper>
                            </Form>
                        </FormContent>
                    </FormWrap>
                </Container>
            );
        } else {
            return (
                <Container>
                    <FormWrap>
                        <FormContent>
                        <Form onSubmit={this.handleSubmitCode}>
                            <IconWrapper href="https://annal.app">
                                <Icon src={logo}/>
                            </IconWrapper>
                            <FormH1>Enter code</FormH1>
                            <FormAlert id="alert"></FormAlert>
                            <FormLabel htmlFor='code'>Code</FormLabel>
                            <FormInput id="code" type='code' required />
                            <FormButton id="submit" type='submit' >Confirm Code</FormButton>
                            <FormLinksWrapper>
                                <FormLink to="/auth/sign-in">Have an account?</FormLink>
                            </FormLinksWrapper>
                        </Form>
                        </FormContent>
                    </FormWrap>
                </Container>
            );
        }
    }
};

export default SignUp;