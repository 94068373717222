import React from "react";
import "App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Home from "page";
import SignIn from "page/auth/SignIn";
import SignUp from "page/auth/SignUp";
import SignOut from "page/auth/SignOut";
import ForgotPassword from "page/auth/ForgotPassword";
import Dashboard from "page/dash/Dashboard";

function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/auth/sign-in" component={SignIn} exact />
                    <Route path="/auth/sign-up" component={SignUp} exact />
                    <Route path="/auth/sign-out" component={SignOut} exact />
                    <Route path="/auth/forgot-password" component={ForgotPassword} exact />

                    <Route path="/dashboard" component={Dashboard} exact />
                </Switch>
            </Router>
            <CookieConsent 
                location="bottom"
                buttonText="Accept"
                style={{ background: "#1d1918" }}
                buttonStyle={{ background: "#992f30", color: "#fff" }}
            >We use cookies to improve user experience and analyze website traffic. By clicking “Accept“, you agree to our website's cookie use as described in our <a href="https://annal.app/cookie-policy">Cookie Policy</a>.</CookieConsent>
        </>
    );
}

export default App;
