const Home = () => {
    // Check to see if the user is logged in.
    // If they are, redirect them to the dashboard.
    // If they are not, redirect them to the sign in page.
    if (window.localStorage.getItem("token")) {
        window.location.href = "/dashboard";
    } else {
        window.location.href = "/auth/sign-in";
    }
    return null;
};

export default Home;
