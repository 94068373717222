import React from "react";
import Dashboard from "components/dash/Dashboard";

function DashboardPage() {
    return (
        <>
            <Dashboard />
        </>
    );
}

export default DashboardPage;
