import React from 'react';
import logo from "images/annal-logo.svg";
import {
    Container,
    FormWrap,
    Icon,
    IconWrapper,
    FormContent,
    Form,
    FormH1,
    FormLabel,
    FormInput,
    FormButton,
    FormLink,
    FormLinksWrapper,
    FormAlert
} from 'components/FormElements';

class SignIn extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.setAlert("");
        // Send a POST request to https://' + process.env.ANNAL_API +'/auth/login
        // If the request fails, display an error message
        // If the request succeeds, save the token to localStorage and redirect to the dashboard
        const HTTP = new XMLHttpRequest();
        const url = process.env.REACT_APP_ANNAL_API +'/auth/login';
        const data = new URLSearchParams(Object.entries({
            username: document.getElementById('email').value,
            password: document.getElementById('password').value
        })).toString();
        HTTP.open('POST', url);
        HTTP.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        HTTP.onload = () => {
            if (HTTP.status === 200) {
                const response = JSON.parse(HTTP.responseText);
                window.localStorage.setItem('token', response.access_token);
                window.location.href = '/dashboard';
            } else if (HTTP.status === 521) {
                this.setAlert("The API server is down. Please try again later.", true, "Sign In");
            } else if (HTTP.status.toString().startsWith("5")) {
                this.setAlert("There was a problem with the request.", true, "Sign In");
            } else if (HTTP.status === 401) {
                this.setAlert("Invalid username or password", true, "Sign In");
            } else if (HTTP.status === 429) {
                this.setAlert("Too many requests. Please try again later.", true, "Sign In");
            } else {

            }
        }
        // Set button to loading and disable it and the form
        document.getElementById('submit').disabled = true;
        document.getElementById('submit').innerHTML = "Loading...";
        document.getElementById('email').disabled = true;
        document.getElementById('password').disabled = true;

        HTTP.send(data);
    }

    setAlert = (message, error = true) => {
        if (error) {
            document.getElementById('alert').style.color = "red";
        } else {
            document.getElementById('alert').style.color = "green";
        }

        document.getElementById('alert').innerHTML = message;

        // Reverse the form changes
        document.getElementById('submit').disabled = false;
        document.getElementById('submit').innerHTML = "Sign In";
        document.getElementById('email').disabled = false;
        document.getElementById('password').disabled = false;
    }

    render () {
        return (
            <Container>
                <FormWrap>
                    <FormContent>
                        <Form onSubmit={this.handleSubmit}>
                            <IconWrapper href="https://annal.app">
                                <Icon src={logo}/>
                            </IconWrapper>
                            <FormH1>CLOSED ALPHA</FormH1>
                            <FormH1>Sign in to your account</FormH1>
                            <FormAlert id="alert"></FormAlert>
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <FormInput id="email" type='email' required />
                            <FormLabel htmlFor='password'>Password</FormLabel>
                            <FormInput id="password" type='password' required />
                            <FormButton id="submit" type='submit' >Sign In</FormButton>
                            <FormLinksWrapper>
                                <FormLink to="/auth/forgot-password">Forgot Password?</FormLink>
                                <FormLink to="/auth/sign-up">Don't have an account?</FormLink>
                            </FormLinksWrapper>
                        </Form>
                    </FormContent>
                </FormWrap>
            </Container>
        );
    }
};

export default SignIn;