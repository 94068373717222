import React from "react";
import SignUp from "components/SignUp";

function SignUpPage() {
    return (
        <>
            <SignUp />
        </>
    );
}

export default SignUpPage;
