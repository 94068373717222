import React from "react";
import Home from "components/home/";

function HomePage() {
    return (
        <>
            <Home />
        </>
    );
}

export default HomePage;
