import React from 'react';
import logo from "images/annal-logo.svg";
import {
  Container,
  FormWrap,
  Icon,
  IconWrapper,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  FormLink,
  FormLinksWrapper,
  FormAlert
} from 'components/FormElements';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codeNeeded: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        // Send a POST request to https://' + process.env.ANNAL_API +'/auth/reset
        // If the request fails, display an error message
        // If the request succeeds, change the form page to get the code and new password
        const HTTP = new XMLHttpRequest();
        const url = process.env.REACT_APP_ANNAL_API +'/auth/reset';
        const data = new URLSearchParams(Object.entries({
            email: document.getElementById('email').value
        })).toString();
        HTTP.open('POST', url);
        HTTP.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        HTTP.onload = () => {
            if (HTTP.status === 200) {
                this.setState({codeNeeded: true});
                this.setAlert("An email has been sent to you with a code.", false, "Reset Password");
                document.getElementById("code").value = "";
            } else if (HTTP.status === 521) {
                this.setAlert("The API server is down. Please try again later.", true, "Reset Password");
            } else if (HTTP.status.toString().startsWith("5")) {
                this.setAlert("There was a problem with the request.", true, "Reset Password");
            } else if (HTTP.status === 429) {
                this.setAlert("Too many requests. Please try again later.", true, "Reset Password");
            } else {
                this.setAlert("Invalid email");
            }
        }
        // Set button to loading and disable it and the form
        document.getElementById('submit').disabled = true;
        document.getElementById('submit').innerHTML = "Loading...";
        document.getElementById('email').disabled = true;
        this.setAlert("");

        HTTP.send(data);
    }

    handleSubmitCode = (e) => {
        e.preventDefault();
        this.setAlert("");
        // Check to see if the passwords match
        if (document.getElementById('password').value !== document.getElementById('password-confirm').value) {
            this.setAlert("Passwords do not match", true, "Reset Password");
            return;
        }

        // Send a PUT request to https://' + process.env.ANNAL_API +'/auth/{code}
        // If the request fails, display an error message
        // If the request succeeds, redirect to the sign in page
        const HTTP = new XMLHttpRequest();
        const url = process.env.REACT_APP_ANNAL_API +'/auth/' + document.getElementById('code').value;
        const data = new URLSearchParams(Object.entries({
            password: document.getElementById('password').value
        })).toString();
        HTTP.open('PUT', url);
        HTTP.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        HTTP.onload = () => {
            if (HTTP.status === 200) {
                window.location.href = "/auth/sign-in";
            } else if (HTTP.status === 521) {
                this.setAlert("The API server is down.", true, "Reset Password");
            } else if (HTTP.status.toString().startsWith("5")) {
                this.setAlert("There was a problem with the request.", true, "Reset Password");
            } else {
                this.setAlert("Invalid code", true, "Reset Password");
            }
        }
        // Set button to loading and disable it and the form
        document.getElementById('submit').disabled = true;
        document.getElementById('submit').innerHTML = "Loading...";
        document.getElementById('code').disabled = true;
        document.getElementById('password').disabled = true;
        document.getElementById('password-confirm').disabled = true;

        HTTP.send(data);
    }

    setAlert = (message, error = true, buttonText = "Continue") => {
        document.getElementById('alert').innerHTML = message;

        if (error) {
            document.getElementById('alert').style.color = "red";
        } else {
            document.getElementById('alert').style.color = "green";
        }

        // Reverse the form changes
        document.getElementById('submit').disabled = false;
        document.getElementById('submit').innerHTML = buttonText;
        try {
            document.getElementById('email').disabled = false;
        } catch (TypeError) {
            // Do nothing
        }
        try {
            document.getElementById('code').disabled = false;
            document.getElementById('password').disabled = false;
            document.getElementById('password-confirm').disabled = false;
        } catch (TypeError) {
            // Do nothing
        }
    }


    render() {
        if (!this.state.codeNeeded) {
            return (
                <Container>
                    <FormWrap>
                        <FormContent>
                            <Form onSubmit={this.handleSubmit}>
                                <IconWrapper href="https://annal.app">
                                    <Icon src={logo}/>
                                </IconWrapper>
                                <FormH1>Reset Password</FormH1>
                                <FormAlert id="alert"></FormAlert>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <FormInput id="email" type='email' required />
                                <FormButton id='submit' type='submit' >Continue</FormButton>
                                <FormLinksWrapper>
                                    <FormLink to="/auth/sign-in">Change your mind?</FormLink>
                                </FormLinksWrapper>
                            </Form>
                        </FormContent>
                    </FormWrap>
                </Container>
            );
        } else {
            return (
                <Container>
                    <FormWrap>
                        <FormContent>
                            <Form onSubmit={this.handleSubmitCode}>
                                <IconWrapper href="https://annal.app">
                                    <Icon src={logo}/>
                                </IconWrapper>
                                <FormH1>Enter Code</FormH1>
                                <FormAlert id="alert"></FormAlert>
                                <FormLabel htmlFor='code'>Code</FormLabel>
                                <FormInput id="code" type='code' required />
                                <FormLabel htmlFor='password'>Password</FormLabel>
                                <FormInput id="password" type='password' required />
                                <FormLabel htmlFor='password-confirm'>Confirm Password</FormLabel>
                                <FormInput id="password-confirm" type='password' required />
                                <FormButton id='submit' type='submit' >Reset Password</FormButton>
                                <FormLinksWrapper>
                                    <FormLink to="/auth/sign-in">Change your mind?</FormLink>
                                </FormLinksWrapper>
                            </Form>
                        </FormContent>
                    </FormWrap>
                </Container>
            );
        }
    }
};

export default ForgotPassword;