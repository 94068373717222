import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    overflow: hidden;
    background: #992f30;
`;

export const FormWrap = styled.div`
  height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 400px) {
        height: 80%;
    }
`;

export const Icon = styled.img`
    max-width: 80%;
    position: center;
`

export const IconWrapper = styled.a`
    margin-left: 32px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;
    margin-left: 1rem;
    text-align: center;
`;

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 480px) {
    padding: 10px;
    }
`;

export const Form = styled.form`
    background: #1d1918;
    max-width: 400px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 30px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
    @media screen and (max-width: 400px) {
    padding: 32px 32px;
    }
`;

export const FormH1 = styled.h1`
    margin-bottom: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`;

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
`;
export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
`;

export const FormButton = styled.button`
    background: #992f30;
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    &[disabled] {
    background: #5e292a;
    cursor: auto;
    }

    &:hover {
    background: #5e292a;
    }
`;
export const FormLink = styled(Link)`
    background: #992f30;
    margin-top: 8px;
    padding: 8px 0;
    text-decoration: none;
    text-align: center;
    border: none;
    padding-left: 64px;
    padding-right: 64px;
    border-radius: 16px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    &[disabled] {
    background: #5e292a;
    cursor: auto;
    }
    &:hover {
        background: #5e292a;
    }
`;

export const FormLinksWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 32px;
    flex-direction: column;
    align-items: center;
`;

export const FormAlert = styled.h3`
    color: red;
    font-size: 12px;
    text-align: center;
`

export const FormSmallLink = styled.a`
    color: #4932ff;
`

export const FormText = styled.p`
    color: #fff;
    text-align: center;
    margin-bottom: 1rem;
`