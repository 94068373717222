import React from "react";

function SignOutPage() {
    if (localStorage.getItem("token") === null) {
        window.location.href = "/auth/sign-in";
    } else {
        // Make a post request to /auth/logout
        const HTTP = new XMLHttpRequest();
        const url = process.env.REACT_APP_ANNAL_API + "/auth/logout";
        HTTP.open("POST", url);
        // Set authorization header
        HTTP.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("token"));
        HTTP.onload = () => {
            localStorage.removeItem("token");
            window.location.href = "/auth/sign-in";
        }
        HTTP.send();
    }
    
    return (
        <></>
    );
}

export default SignOutPage;
