import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Button = styled.a`
    background: #1d1918;
    padding: 16px 0;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    padding-left: 64px;
    padding-right: 64px;
    border-radius: 16px;
    margin-top: 2rem;
    text-decoration: none;
    &[disabled] {
    background: #171413;
    cursor: auto;
    }

    &:hover {
    background: #433937;
    }
`;