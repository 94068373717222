import React from "react";
import SignIn from "components/SignIn";

function SignInPage() {
    return (
        <>
            <SignIn />
        </>
    );
}

export default SignInPage;
